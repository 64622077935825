























































import {
  defineComponent,
  Ref,
  ref,
  onMounted,
  useFetch,
  useRoute,
} from '@nuxtjs/composition-api';
import { useCache, CacheTagPrefix } from '@vue-storefront/cache';
import { isArray } from 'lodash-es';
import { StrapiSection } from '~/composables/types';
import { getMetaInfo } from '~/helpers/getMetaInfo';
import { useContent, useProduct } from '~/composables';
import { useUiState } from '~/composables/useUiState';

import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';

export default defineComponent({
  name: 'HomePage',
  components: {
    LoadWhenVisible,
    HeroSection: () => import('~/components/Pages/home/HeroSection/index.vue'),
    CategoriesSection: () => import('~/components/Pages/home/CategoriesSection.vue'),
    ReviewsPreviewSection: () => import('~/components/Pages/home/ReviewsPreviewSection/index.vue'),
    BannerSection: () => import('~/components/Pages/home/BannerSection.vue'),
    BlogPreviewSection: () => import('~/components/Pages/common/BlogPreviewSection/index.vue'),
    ProductsSection: () => import('~/components/Pages/common/ProductsSection/index.vue'),
    PostsSection: () => import('~/components/Pages/common/PostsSection/index.vue'),
  },

  setup() {
    const HOME_DISPLAY_TRENDING_PRODUCTS = ref(false);
    const HOME_DISPLAY_BLOGS = ref(false);
    const HOME_DISPLAY_SOCIAL_POSTS = ref(false);

    const { addTags } = useCache();
    const { loadPage, loadPageSections, getSection } = useContent();
    const { isCartSidebarOpen, toggleCartSidebar } = useUiState();
    const { loadTrendingProducts } = useProduct();

    const route = useRoute();
    const page = ref(null);
    const banners = ref([
      {
        title: 'bye, costly shipping for oversized art',
        footer: 'hello, wall-to-wall print that wows',
        image: {
          src: 'defaults/home-banner-image.png',
          alt: 'How We Made It Possible',
          tag: 'Banner image',
        },
        button: {
          message: 'How We Made It Possible',
          page: 'for-professionals/wholesale',
        },
      },
      {
        title: 'we don\'t just make beautiful art,',
        footer: 'we make them responsibly and sustainably.',
        image: {
          src: 'defaults/home-banner-image.png',
          alt: 'Our Commitment to Sustainability',
          tag: 'Banner image',
        },
        button: {
          message: 'Our Commitment to Sustainability',
          page: 'inside-modern-squared/sustainability',
        },
      },
    ]);
    const trendingProducts = ref([]);
    const sections: Ref<Array<StrapiSection>> = ref([]);

    const heroSection: Ref<StrapiSection> = ref(null);
    const categoriesSectionTitle: Ref<string> = ref('');
    const reviewsSection: Ref<StrapiSection> = ref(null);
    const bannersSection: Ref<StrapiSection> = ref(null);
    const productsSection: Ref<StrapiSection> = ref(null);

    const REFCategoriesSection: Ref<any> = ref(null);

    const jumpToCategoriesSection = () => {
      REFCategoriesSection?.value?.$el?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    const loadSections = () => {
      if (!!sections.value && isArray(sections.value)) {
        heroSection.value = getSection('hero-section', sections.value);
        const categoriesSection = getSection(
          'categories-section',
          sections.value,
        );
        categoriesSectionTitle.value = categoriesSection?.content?.sectionTitle;
        reviewsSection.value = getSection('reviews-section', sections.value);
        bannersSection.value = getSection('banners-section', sections.value);
        banners.value = bannersSection.value?.content?.banners;
        productsSection.value = getSection('products-section', sections.value);
      }
    };

    const loadTrendingProductsBySKU = async () => {
      // TO-DO: remove if unused
      const SKUs: string[] = ['MSQ20126', 'MSQ20114', 'MSQ20114', 'MSQ20116'];
      const list = await loadTrendingProducts(SKUs);
      /*       trendingProducts.value = [
        {
          image: {
            url: 'defaults/home-banner-image.png',
          },
          name: 'Abstract Textured',
          price: 'From $ 99',
          media_gallery: [{ url: 'defaults/product-square-image.png' }],
        },
        {
          image: {
            url: 'defaults/product-near-square-image.png',
          },
          name: 'Abstract Textured',
          price: 'From $ 99',
          media_gallery: [],
        },
        {
          image: {
            url: 'defaults/product-square-image.png',
          },
          name: 'Abstract Textured',
          price: 'From $ 99',
          media_gallery: [{ url: 'defaults/product-square-image.png' }],
        },
        {
          image: {
            url: 'defaults/home-banner-image.png',
          },
          name: 'Abstract Textured',
          price: 'From $ 99',
          media_gallery: [{ url: 'defaults/product-square-image.png' }],
        },
        {
          image: {
            url: 'defaults/product-square-image.png',
          },
          name: 'Abstract Textured',
          price: 'From $ 99',
          media_gallery: [{ url: 'defaults/home-banner-image.png' }],
        },
        {
          image: {
            url: 'defaults/home-banner-image.png',
          },
          name: 'Abstract Textured',
          price: 'From $ 99',
          media_gallery: [],
        },
      ]; */
      trendingProducts.value = list.items;
    };

    useFetch(async () => {
      page.value = await loadPage({ identifier: 'home' });
      sections.value = await loadPageSections({ identifier: 'home' });
      loadSections();
    });

    onMounted(() => {
      addTags([{ prefix: CacheTagPrefix.View, value: 'home' }]);
      if (route.value.hash === '#categories-section') {
        setTimeout(() => {
          if (isCartSidebarOpen.value) {
            toggleCartSidebar();
          }
          jumpToCategoriesSection();
        }, 500);
      }
    });

    return {
      HOME_DISPLAY_TRENDING_PRODUCTS,
      HOME_DISPLAY_BLOGS,
      HOME_DISPLAY_SOCIAL_POSTS,
      jumpToCategoriesSection,
      loadTrendingProductsBySKU,
      REFCategoriesSection,
      banners,
      page,
      trendingProducts,
      heroSection,
      categoriesSectionTitle,
      reviewsSection,
      productsSection,
    };
  },
  head() {
    return getMetaInfo(this.page);
  },
});
